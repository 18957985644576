// import function to register Swiper custom elements
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper";

var swiper = new Swiper(".swiper-centered", {
  modules: [Autoplay, Navigation],
  slidesPerView: 3,
  spaceBetween: 30,
  initialSlide: 1,
  centeredSlides: true,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  autoplay: {
    delay: 5000,
  },
  speed: 600,
});

// });
